import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { JobType, ModuleTypeType } from 'common/dist/types/job';
import { onDragStart } from '../job-group-editor/flowUtils';
import { Summary } from 'common/dist/types/habitat';
import { CodeCapsulesPerHabitatCode } from '../job-group-editor/types';
import { CodeCapsule } from 'common/dist/types/codeCapsule';
import { AugurType } from 'common/dist/types/augur';
import { DatapoolType } from 'common/dist/types/datapool';
import TabLineBubbles from '../../tab-line-bubbles/TabLineBubbles';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import msgsOrchestration from 'common/dist/messages/orchestration';

export interface Props {
  summary: Summary;
  codeCapsulesPerHabitatCode: CodeCapsulesPerHabitatCode;
  isAdmin: boolean;
  setJobToAdd: Dispatch<SetStateAction<Partial<JobType>>>;
}

/** Used as a pseudo habitat code */
const OTHER_JOBS_CODE = 'other-jobs';

const OtherList: FC<{
  isAdmin: boolean;
  setJobToAdd: Dispatch<SetStateAction<Partial<JobType>>>;
}> = (props) => {
  const backupJob: Partial<JobType> = {
    superType: 'backup',
    moduleType: 'backup',
    // jobType: '',
    // templateId: '',
    // backupId: '',
  };
  return (
    <div className={styles.list}>
      <div className={styles.listHeader}>
        <strong>Other</strong>
      </div>
      <ul>
        {props.isAdmin && (
          <li>
            <div className={styles.listItem}>
              <div
                onDragStart={(event) => {
                  onDragStart(event, backupJob);
                }}
                draggable
                onClick={() => {
                  props.setJobToAdd(backupJob);
                }}
              >
                <Node description={'Backup'} />
              </div>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

const AugurList: FC<{
  augurs: AugurType[];
  setJobToAdd: Dispatch<SetStateAction<Partial<JobType>>>;
}> = ({ augurs, setJobToAdd }) => {
  return (
    <div className={styles.list} data-testingidentifier={'Augurslist'}>
      <div className={styles.listHeader}>
        <strong>Augurs</strong>
      </div>
      <ul>
        {augurs.map((augur) => {
          const archetype = augur.archetypeVersion.archetype;
          const archetypeVersion = augur.archetypeVersion;
          const archetypeInformation = {
            name: archetype?.name,
            code: archetype?.code,
            versionNumber: archetypeVersion?.number,
            isCustom: archetype?.custom,
          };
          const executionType = archetypeVersion.executionType;
          const augurJob: Partial<JobType> = {
            superType: 'augur',
            moduleType: augur.type as ModuleTypeType,
            augurCode: augur.code,
            archetypeInformation: archetypeInformation,
            executionType,
          };
          return (
            <li key={augur.code}>
              <div className={styles.listItem}>
                <div
                  className={styles.listItemText}
                  data-testingidentifier={augur.name}
                >
                  {augur.name}
                </div>
                {executionType === undefined ? (
                  <FormattedMessage
                    {...msgsOrchestration.addEditJobGroupRepositoryErrorNoExecutionType}
                  />
                ) : (
                  <div style={{ display: 'flex' }}>
                    {augur.archetypeVersion?.supportsLearning && (
                      <div
                        onDragStart={(event) => {
                          augurJob.jobType = 'learning';
                          onDragStart(event, augurJob);
                        }}
                        draggable
                        onClick={() => {
                          augurJob.jobType = 'learning';
                          setJobToAdd(augurJob);
                        }}
                      >
                        <Node description={'Learning'} />
                      </div>
                    )}
                    {augur.archetypeVersion?.supportsEvaluation && (
                      <div
                        onDragStart={(event) => {
                          augurJob.jobType = 'evaluation';
                          onDragStart(event, augurJob);
                        }}
                        draggable
                        onClick={() => {
                          augurJob.jobType = 'evaluation';
                          setJobToAdd(augurJob);
                        }}
                      >
                        <Node description={'Evaluation'} />
                      </div>
                    )}
                    {augur.archetypeVersion?.supportsPrediction && (
                      <div
                        onDragStart={(event) => {
                          augurJob.jobType = 'prediction';
                          onDragStart(event, augurJob);
                        }}
                        draggable
                        onClick={() => {
                          augurJob.jobType = 'prediction';
                          setJobToAdd(augurJob);
                        }}
                      >
                        <Node description={'Prediction'} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const DatapoolList: FC<{
  datapools: DatapoolType[];
  setJobToAdd: Dispatch<SetStateAction<Partial<JobType>>>;
}> = (props) => {
  return (
    <div className={styles.list} data-testingidentifier={'Datapoolslist'}>
      <div className={styles.listHeader}>
        <strong>Datapools</strong>
      </div>
      <ul>
        {props.datapools.map((datapool) => {
          const datapoolJob: Partial<JobType> = {
            superType: 'datapool',
            moduleType: datapool.moduleType as ModuleTypeType,
            jobType: 'datapool-statistics',
            datapoolCode: datapool.code,
          };
          return (
            <li key={datapool.code}>
              <div className={styles.listItem}>
                <div
                  className={styles.listItemText}
                  data-testingidentifier={datapool.name}
                >
                  {datapool.name}
                </div>
                <div
                  onDragStart={(event) => {
                    onDragStart(event, datapoolJob);
                  }}
                  draggable
                  onClick={() => {
                    props.setJobToAdd(datapoolJob);
                  }}
                >
                  <Node description={'Statistics'} />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const CodeCapsuleList: FC<{
  codeCapsules: CodeCapsule[];
  setJobToAdd: Dispatch<SetStateAction<Partial<JobType>>>;
}> = (props) => {
  return (
    <div className={styles.list} data-testingidentifier={'CClist'}>
      <div className={styles.listHeader}>
        <strong>Code Capsules</strong>
      </div>
      <ul>
        {props.codeCapsules.map((codeCapsule) => {
          const codeCapsuleJob: Partial<JobType> = {
            superType: 'code-capsule',
            moduleType: 'code-capsule',
            jobType: 'run-code-capsule',
            repositoryCode: codeCapsule.repoCode,
            codeCapsuleCode: codeCapsule.code,
          };
          return (
            <li key={codeCapsule.code}>
              <div className={styles.listItem}>
                <div
                  className={styles.listItemText}
                  data-testingidentifier={codeCapsule.name}
                >
                  {codeCapsule.name}
                </div>
                <div
                  onDragStart={(event) => {
                    onDragStart(event, codeCapsuleJob);
                  }}
                  draggable
                  onClick={() => {
                    props.setJobToAdd(codeCapsuleJob);
                  }}
                >
                  <Node description={'Run'} />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Node: FC<{ description: string }> = (props) => {
  return (
    <div className={styles.node} data-testingidentifier={props.description}>
      {props.description}
    </div>
  );
};

const JobGroupEditorRepository: FC<Props> = (props) => {
  const [selectedHabitat, setSelectedHabitat] = useState<{
    name?: string;
    code?: string;
  }>({ name: OTHER_JOBS_CODE, code: OTHER_JOBS_CODE });
  const [filter, setFilter] = useState('');

  // When the summary was loaded, select the first habitat tab (if there is habitat)
  useEffect(() => {
    const firstHabitat = props.summary[0];
    if (firstHabitat) {
      setSelectedHabitat({
        name: firstHabitat.name,
        code: firstHabitat.code,
      });
    }
  }, [props.summary]);

  const codeCapsules =
    props.codeCapsulesPerHabitatCode[selectedHabitat.code] || [];
  const habitat = props.summary.find(
    (habitat) => habitat.code === selectedHabitat.code
  );
  const dataPools = habitat?.datapools || [];
  const augurs = habitat?.augurs || [];
  return (
    <div className={styles.jobGroupEditor}>
      <div className={styles.header}>
        <div className={styles.tabs}>
          <TabLineBubbles
            buttons={[
              {
                id: OTHER_JOBS_CODE,
                intlId: 'none',
                intlDefault: 'Other Jobs',
                title: 'Other Jobs',
                onClick: () =>
                  setSelectedHabitat({
                    name: OTHER_JOBS_CODE,
                    code: OTHER_JOBS_CODE,
                  }),
              },
              ...props.summary.map((habitat) => ({
                id: habitat.code,
                intlId: 'none',
                intlDefault:
                  habitat.name.length < 10
                    ? habitat.name
                    : habitat.name.slice(0, 10) + '...',
                title: habitat.name,
                icon: () => <span className='icon-home' />,
                onClick: () => {
                  setSelectedHabitat({
                    name: habitat.name,
                    code: habitat.code,
                  });
                },
              })),
            ]}
            activeButton={selectedHabitat.code}
            design={'tabs'}
          />
        </div>
        <input
          type={'text'}
          value={filter}
          placeholder={'Filter Jobs'}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <div className={styles.categories}>
        {selectedHabitat.code === 'other-jobs' ? (
          <OtherList isAdmin={props.isAdmin} setJobToAdd={props.setJobToAdd} />
        ) : (
          <>
            <AugurList
              // @ts-ignore
              augurs={
                filter === ''
                  ? augurs
                  : augurs.filter((aug) =>
                      aug.name.toLowerCase().includes(filter.toLowerCase())
                    )
              }
              setJobToAdd={props.setJobToAdd}
            />
            <DatapoolList
              // @ts-ignore
              datapools={
                filter === ''
                  ? dataPools
                  : dataPools.filter((dp) =>
                      dp.name.toLowerCase().includes(filter.toLowerCase())
                    )
              }
              setJobToAdd={props.setJobToAdd}
            />
            <CodeCapsuleList
              codeCapsules={
                filter === ''
                  ? codeCapsules
                  : codeCapsules.filter((cc) =>
                      cc.name.toLowerCase().includes(filter.toLowerCase())
                    )
              }
              setJobToAdd={props.setJobToAdd}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default JobGroupEditorRepository;
